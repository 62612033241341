<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="tab in tabs"
        :key="tab.route"
        :to="{ name: $route.matched[1].meta[tab.route] }"
        exact
        :active="
          tab.route === 'routeCompleted' ? isCompletedTab : isActive(tab.status)
        "
        :link-classes="['px-3', bgTabsNavs]"
      >
        {{ tab.label }}
        <span
          v-if="tab.route === 'routePending' && G_PENDING_APPOINTMENTS > 0"
          class="ml-2"
        >
          <feather-icon
            icon
            :badge="
              G_PENDING_APPOINTMENTS > 99 ? '99+' : G_PENDING_APPOINTMENTS
            "
            badge-classes="badge-danger"
          />
        </span>
        <span
          v-else-if="
            tab.route === 'routeInProcess' && G_IN_PROCESS_APPOINTMENTS > 0 && isSpecialist
          "
          class="ml-2"
        >
          <feather-icon
            icon
            :badge="
              G_IN_PROCESS_APPOINTMENTS > 99 ? '99+' : G_IN_PROCESS_APPOINTMENTS
            "
            badge-classes="badge-danger"
          />
        </span>
        <span
          v-else-if="
            tab.route === 'routeCompleted' && G_COMPLETED_APPOINTMENTS > 0 && isSpecialist
          "
          class="ml-2"
        >
          <feather-icon
            icon
            :badge="
              G_COMPLETED_APPOINTMENTS > 99 ? '99+' : G_COMPLETED_APPOINTMENTS
            "
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" :program_id="programId" />
    </b-card>
  </div>
</template>

<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  data() {
    return {
      tabs: [
        { label: "PENDING", route: "routePending", status: 1 },
        { label: "IN PROCESS", route: "routeInProcess", status: 2 },
        { label: "COMPLETED", route: "routeCompleted", status: null },
        { label: "EXPIRED", route: "routeExpired", status: 5 },
      ],
    };
  },
  async created() {
    await this.A_GET_PENDING_APPOINTMENTS();
  },
  computed: {
    isSpecialist() {
      return this.$route.matched[1].meta.module == 29;
    },
    programId() {
      return this.$route.matched[1].meta.program;
    },
    statusApp() {
      return this.$route.meta.statusApp;
    },
    isCompletedTab() {
      const { routeAccepted, routeRejected } = this.$route.matched[1].meta;
      return [routeAccepted, routeRejected].includes(this.$route.name);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_PENDING_APPOINTMENTS: "AppointmentStore/G_PENDING_APPOINTMENTS",
      G_IN_PROCESS_APPOINTMENTS: "AppointmentStore/G_IN_PROCESS_APPOINTMENTS",
      G_COMPLETED_APPOINTMENTS: "AppointmentStore/G_COMPLETED_APPOINTMENTS"
    }),
  },
  methods: {
    isActive(tabStatus) {
      return this.statusApp === tabStatus;
    },
    ...mapMutations({
      M_PENDING_APPOINTMENTS: "AppointmentStore/M_PENDING_APPOINTMENTS",
    }),
    ...mapActions({
      A_GET_PENDING_APPOINTMENTS: "AppointmentStore/A_GET_PENDING_APPOINTMENTS",
    }),
  },
};
</script>
